import { Box, Button, Divider, Heading, Text } from "@chakra-ui/react";
import { useAppContext } from "contexts/AppContext";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserBalance } from "redux/slices/walletSlice";
import { delay, formatTokenAmount, roundDown, roundUp } from "utils";
import { execContractQuery, execContractTx } from "utils/contracts";
import launchpad from "utils/contracts/launchpad";
import TabLayout from "../Layout";
import { formatChainStringToNumber } from "utils";
import { formatNumDynDecimal } from "utils";
import { appChain } from "constants";
import { formatTokenAmountNumber } from "utils";

const Row = ({ label, value, divider = false, ...rest }) => {
  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          ...rest,
        }}
      >
        <Text>{label}</Text>
        <Text>{value}</Text>
      </Box>
      {divider && <Divider />}
    </>
  );
};
const PhaseTag = ({ data, launchpadData }) => {
  //   const publicBuyer = publicBalance.find((e) => e?.phaseID == index);
  const { token } = launchpadData?.projectInfo || {};
  const { currentAccount } = useSelector((s) => s.wallet);
  const { api } = useAppContext();
  const [publicBalance, setPublicBalance] = useState(null);
  const [WLBalance, setWLBalance] = useState(null);
  const dispatch = useDispatch();
  const isSaleEnd = useMemo(() => {
    const endTime = new Date(parseInt(data?.endTime?.replace(/,/g, "")));
    const now = new Date();
    return now > endTime
  }, [api, currentAccount])

  const publicClaimHandler = async () => {
    try {
      const endTime = new Date(parseInt(data?.endTime?.replace(/,/g, "")));
      const now = new Date();
      if (now > endTime) {
        const vestingDuration = parseInt(
          data?.vestingDuration?.replace(/,/g, "")
        );
        // const endVestingTime = parseInt(endTime.getTime()) + vestingDuration;
        //   console.log(endVestingTime)

        const vestingUnit = parseInt(data?.vestingUnit?.replaceAll(/,/g, ""));
        const claimNumberOfTime = roundUp(vestingDuration / vestingUnit, 0);

        const purchasedAmount = parseFloat(publicBalance?.purchasedAmount);
        const vestingAmount =
          purchasedAmount *
          (1 -
            parseFloat(data?.immediateReleaseRate?.replaceAll(/,/g, "")) /
            10000);
        const claimUnit = roundDown(vestingAmount / claimNumberOfTime);

        const claimedAmount =
          parseFloat(publicBalance?.claimedAmount) -
          (purchasedAmount - vestingAmount);
        const claimedNumberOfTime = roundDown(claimedAmount / claimUnit, 0);
        const claimableNumberOfTime = Math.floor(
          (now.getTime() - endTime.getTime()) / vestingUnit
        );

        if (claimableNumberOfTime > claimedNumberOfTime) {
          await execContractTx(
            currentAccount,
            api,
            launchpad.CONTRACT_ABI,
            launchpadData?.launchpadContract,
            0, //-> value
            "launchpadContractTrait::publicClaim",
            data?.id
          );
          await delay(1000);
          await toast.promise(
            delay(2000).then(() => {
              if (currentAccount) {
                dispatch(fetchUserBalance({ currentAccount, api }));
              }
            }),
            {
              loading: "Fetching new data ... ",
              success: "Done !",
              error: "Could not fetch data!!!",
            }
          );
        } else {
          toast.error("Wait until next claimable time");
        }
      } else {
        toast.error("Phase sale time not ended");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const WLClaimHandler = async () => {
    try {
      // const startTime = new Date(parseInt(data?.startTime?.replace(/,/g, "")));
      const endTime = new Date(parseInt(data?.endTime?.replace(/,/g, "")));
      const now = new Date();
      if (now > endTime) {
        const vestingDuration = parseInt(
          data?.vestingDuration?.replace(/,/g, "")
        );
        // const endVestingTime = parseInt(endTime.getTime()) + vestingDuration;
        //   console.log(endVestingTime)

        const vestingUnit = parseInt(data?.vestingUnit?.replace(/,/g, ""));
        const claimNumberOfTime = roundUp(vestingDuration / vestingUnit, 0);

        const purchasedAmount = parseFloat(WLBalance?.purchasedAmount);

        const vestingAmount =
          purchasedAmount *
          (1 -
            parseFloat(data?.immediateReleaseRate.replaceAll(/,/g, "")) /
            10000);
        const claimUnit = roundDown(vestingAmount / claimNumberOfTime);

        const claimedAmount =
          parseFloat(WLBalance?.claimedAmount) -
          (purchasedAmount - vestingAmount);
        const claimedNumberOfTime = roundDown(claimedAmount / claimUnit, 0);
        const claimableNumberOfTime = Math.floor(
          (now.getTime() - endTime.getTime()) / vestingUnit
        );
        if (claimableNumberOfTime > claimedNumberOfTime) {
          await execContractTx(
            currentAccount,
            api,
            launchpad.CONTRACT_ABI,
            launchpadData?.launchpadContract,
            0, //-> value
            "launchpadContractTrait::whitelistClaim",
            data?.id
          );
          await delay(1000);
          await toast.promise(
            delay(2000).then(() => {
              if (currentAccount) {
                dispatch(fetchUserBalance({ currentAccount, api }));
              }
            }),
            {
              loading: "Fetching new data ... ",
              success: "Done !",
              error: "Could not fetch data!!!",
            }
          );
        } else {
          toast.error("Wait until next claimable time");
        }
      } else {
        toast.error("Phase sale time not ended");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getPublicBalance = async () => {
      try {
        const query = await execContractQuery(
          currentAccount?.address,
          api,
          launchpad.CONTRACT_ABI,
          launchpadData?.launchpadContract,
          0,
          "launchpadContractTrait::getPublicBuyer",
          data?.id,
          currentAccount?.address
        );
        const publicBuyer = query.toHuman().Ok;
        console.log(publicBuyer);

        const decimalToken = parseInt(token.decimals);
        setPublicBalance({
          claimedAmount: +formatTokenAmountNumber(
            publicBuyer?.claimedAmount || 0,
            decimalToken
          ),
          lastUpdatedTime: +formatTokenAmountNumber(
            publicBuyer?.lastUpdatedTime,
            decimalToken
          ),
          purchasedAmount: +formatTokenAmountNumber(
            publicBuyer?.purchasedAmount || 0,
            decimalToken
          ),
          vestingAmount: +formatTokenAmountNumber(
            publicBuyer?.vestingAmount || 0,
            decimalToken
          ),
        });
      } catch (error) {
        console.log(error);
      }
    };
    const getWLBalance = async () => {
      try {
        const query = await execContractQuery(
          currentAccount?.address,
          api,
          launchpad.CONTRACT_ABI,
          launchpadData?.launchpadContract,
          0,
          "launchpadContractTrait::getWhitelistBuyer",
          data?.id,
          currentAccount?.address
        );
        const WLBuyer = query.toHuman().Ok;
        const decimalToken = parseInt(token.decimals);

        setWLBalance({
          claimedAmount: +formatTokenAmountNumber(
            WLBuyer?.claimedAmount,
            decimalToken
          ),
          amount: +formatTokenAmountNumber(WLBuyer?.amount, decimalToken),
          price: +formatTokenAmountNumber(WLBuyer?.price, decimalToken),
          lastUpdatedTime: +formatTokenAmountNumber(
            WLBuyer?.lastUpdatedTime,
            decimalToken
          ),
          purchasedAmount: +formatTokenAmountNumber(
            WLBuyer?.purchasedAmount,
            decimalToken
          ),
          vestingAmount: +formatTokenAmountNumber(
            WLBuyer?.vestingAmount,
            decimalToken
          ),
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (data?.publicSaleInfor?.isPublic) getPublicBalance();
    if (data?.whitelist?.length) getWLBalance();
  }, [api, currentAccount?.address, data, launchpadData, token.decimals]);

  const publicPhaseInfo =
    formatChainStringToNumber(data.publicSaleInfor.price) /
    Math.pow(10, token.decimals);

  const endTime = parseInt(formatChainStringToNumber(data?.endTime));
  const endVestingTime = parseInt(
    formatChainStringToNumber(data?.endVestingTime)
  );
  const vestingDuration = parseInt(
    formatChainStringToNumber(data?.vestingDuration)
  );
  const vestingUnit = parseInt(formatChainStringToNumber(data?.vestingUnit));
  const totalVestingUnits = parseInt(
    formatChainStringToNumber(data?.totalVestingUnits)
  );

  let nextClaimWl = endTime + vestingUnit;

  const temp_next = calcNextClaimTime(
    endTime,
    vestingUnit,
    vestingDuration,
    totalVestingUnits
  );

  if (temp_next && temp_next[0]) {
    nextClaimWl = temp_next[1];
  }

  const now = Date.now();

  if (now > endVestingTime) {
    nextClaimWl = endVestingTime;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "2px solid #E3DFF3",
        padding: "16px",
        borderRadius: "8px",
        marginBottom: "20px",
      }}
    >
      <Heading size="md" sx={{ marginBottom: "4px" }}>
        {data?.name}
      </Heading>
      <Divider sx={{ marginBottom: "8px" }} />
      {/* {data?.publicSaleInfor?.isPublic && ( */}
      <>
        <Text
          size="md"
          sx={{ color: "#57527E", fontWeight: "500", mt: "16px" }}
        >
          Public Sale
        </Text>
        <Divider sx={{ marginBottom: "8px" }} />
        <Row
          label="Total Purchased"
          value={
            `${formatNumDynDecimal(publicBalance?.purchasedAmount) || 0} ${
              token?.symbol
            }` || 0
          }
        />
        <Row
          label="Public price"
          value={
            `${formatNumDynDecimal(publicPhaseInfo) || 0} ${appChain?.unit}` ||
            0
          }
        />
        <Row
          label="Total Vesting"
          value={
            `${formatNumDynDecimal(publicBalance?.vestingAmount) || 0} ${
              token?.symbol
            }` || 0
          }
        />
        <Row
          label="Claimed"
          value={
            `${formatNumDynDecimal(publicBalance?.claimedAmount) || 0} ${
              token?.symbol
            }` || 0
          }
        />

        <Button
          isDisabled={
            (!(parseFloat(publicBalance?.purchasedAmount) > 0) &&
              publicBalance?.purchasedAmount ===
                publicBalance?.claimedAmount) ||
            !isSaleEnd
          }
          my="8px"
          w="full"
          height="40px"
          variant="outline"
          onClick={() => publicClaimHandler()}
        >
          {isSaleEnd ? "Claim" : "Sale is not ended"}
        </Button>
      </>
      {/* )} */}
      {/* {data?.whitelist?.length > 0 && ( */}
      <>
        <Text
          size="md"
          sx={{ color: "#57527E", fontWeight: "500", mt: "16px" }}
        >
          Whitelist Sale
        </Text>
        <Divider sx={{ marginBottom: "8px" }} />
        <Row
          label="Whitelist Amount"
          value={
            `${formatNumDynDecimal(WLBalance?.amount) || 0} ${token?.symbol}` ||
            0
          }
        />
        <Row
          label="Whitelist Price"
          value={
            `${formatNumDynDecimal(WLBalance?.price) || 0} ${appChain?.unit}` ||
            0
          }
        />
        <Row
          label="Total Purchased"
          value={
            `${formatNumDynDecimal(WLBalance?.purchasedAmount) || 0} ${
              token?.symbol
            }` || 0
          }
        />
        <Row
          label="Total Vesting"
          value={
            `${formatNumDynDecimal(WLBalance?.vestingAmount) || 0} ${
              token?.symbol
            }` || 0
          }
        />
        <Row
          label="Claimed"
          value={
            `${formatNumDynDecimal(WLBalance?.claimedAmount) || 0} ${
              token?.symbol
            }` || 0
          }
        />
        <Button
          isDisabled={(!(parseFloat(WLBalance?.purchasedAmount) > 0)) || !isSaleEnd}
          my="8px"
          w="full"
          height="40px"
          variant="outline"
          onClick={() => WLClaimHandler()}
        >
          {isSaleEnd ? "Claim" : "Sale is not ended"}
        </Button>

        <Divider my="8px" />
        <Row
          label="Next Claim"
          value={`${new Date(parseInt(nextClaimWl)).toLocaleString()} ` ?? "-"}
        />
      </>
      {/* )} */}
      {/* {!(data?.publicSaleInfor?.isPublic || data?.whitelist?.length) && (
        <Text>No data</Text>
      )} */}
    </Box>
  );
};
const BalanceTab = ({ launchpadData }) => {
  const phases = launchpadData?.phaseList;

  return (
    <TabLayout launchpadData={launchpadData}>
      {phases?.map((obj, index) => {
        return (
          <PhaseTag
            key={index}
            data={{ ...obj, id: index }}
            launchpadData={launchpadData}
          />
        );
      })}
    </TabLayout>
  );
};

export default BalanceTab;

function calcNextClaimTime(
  endTime,
  vestingUnit,
  vestingDuration,
  totalVestingUnits
) {
  const now = Date.now();

  const gapTime = now - endTime;
  const claimCount = Math.floor(vestingDuration / gapTime);

  if (gapTime > 0 && claimCount < totalVestingUnits) {
    return [true, endTime + claimCount * vestingUnit];
  }
}
